<template>
  <div id="deliveryNotice">
    <el-dialog
      :title="deliveryNoticeFormTitle"
      width="1200px"
      :visible.sync="deliveryNoticeDialogVisible"
      :close-on-click-modal="false"
      @close="deliveryNoticeDialogClose"
    >
      <el-form
        ref="deliveryNoticeFormRef"
        :model="deliveryNoticeForm"
        :rules="deliveryNoticeFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="客户名称" prop="customerId">
              <el-select
                v-model="deliveryNoticeForm.customerId"
                placeholder="请选择客户"
                clearable
                @change="customerChange"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="地址" prop="receivingAddress">
              <el-input v-model="deliveryNoticeForm.receivingAddress" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="订单号码" prop="orderNo">
              <el-input v-model="deliveryNoticeForm.orderNo" placeholder="请输入订单号码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交货日期" prop="deliveryDate">
              <el-date-picker v-model="deliveryNoticeForm.deliveryDate" placeholder="请选择交货日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交货方式" prop="deliveryWay">
              <el-radio-group v-model="deliveryNoticeForm.deliveryWay">
                <el-radio :label="1">
                  一次交货
                </el-radio>
                <el-radio :label="2">
                  分批交货
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="deliveryNoticeFormTitle !== '发货通知单详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            show-footer
            keep-source
            :data="deliveryNoticeDetailList"
            :footer-method="footerMethod"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="productNo"
              title="产品编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="productId"
              title="产品名称"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: productList,
                optionProps: {
                  label: 'productName',
                  value: 'id'
                },
                events: {
                  change: productChange
                }
              }"
            />
            <vxe-table-column
              field="spec"
              title="型号规格"
              :edit-render="{name: '$input', props: {readonly: true}}"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
            />
            <vxe-table-column
              field="unitPrice"
              title="单价"
              :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
            />
            <vxe-table-column title="金额">
              <template #default="{ row }">
                <span>{{ countAmount(row) }}</span>
              </template>
            </vxe-table-column>
            <vxe-table-column v-if="deliveryNoticeFormTitle !== '发货通知单详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="仓库" prop="">
              <el-input clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="主管" prop="">
              <el-input clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="核准" prop="">
              <el-input clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="填单" prop="">
              <el-input clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="deliveryNoticeDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deliveryNoticeFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="客户名称">
        <el-input v-model="searchForm.companyName" placeholder="请输入客户名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="deliveryNoticePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="companyName" label="客户名称" />
      <el-table-column prop="receivingAddress" label="地址" />
      <el-table-column prop="orderNo" label="订单号码" />
      <el-table-column label="交货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.deliveryDate">{{ scope.row.deliveryDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="交货方式">
        <template slot-scope="scope">
          <span v-if="scope.row.deliveryWay === 1">一次交货</span>
          <span v-if="scope.row.deliveryWay === 2">分批交货</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalPrice" label="总价" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="deliveryNoticePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addDeliveryNotice, deleteDeliveryNotice, updateDeliveryNotice, selectDeliveryNoticeInfo, selectDeliveryNoticeList } from '@/api/sale/deliveryNotice'
import { selectCustomerList } from '@/api/sale/customer'
import { selectProductList } from '@/api/sale/product'
import XEUtils from 'xe-utils'

export default {
  data () {
    return {
      deliveryNoticeDialogVisible: false,
      deliveryNoticeFormTitle: '',
      deliveryNoticeForm: {
        id: '',
        customerId: '',
        receivingAddress: '',
        orderNo: '',
        deliveryDate: '',
        deliveryWay: '',
        totalPrice: '',
        noticeDetailJson: ''
      },
      deliveryNoticeFormRules: {
        customerId: [{ required: true, message: '客户名称不能为空', trigger: ['blur', 'change']}],
        orderNo: [{ required: true, message: '订单号码不能为空', trigger: ['blur', 'change']}],
        deliveryDate: [{ required: true, message: '交货日期不能为空', trigger: ['blur', 'change']}],
        deliveryWay: [{ required: true, message: '交货方式不能为空', trigger: ['blur', 'change']}]
      },
      deliveryNoticePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        companyName: ''
      },
      customerList: [],
      productList: [],
      deliveryNoticeDetailList: []
    }
  },
  created () {
    selectCustomerList().then(res => {
      this.customerList = res.list
    })
    selectProductList().then(res => {
      this.productList = res.list
    })
    selectDeliveryNoticeList(this.searchForm).then(res => {
      this.deliveryNoticePage = res
    })
  },
  methods: {
    deliveryNoticeDialogClose () {
      this.$refs.deliveryNoticeFormRef.resetFields()
      this.deliveryNoticeDetailList = []
    },
    deliveryNoticeFormSubmit () {
      if (this.deliveryNoticeFormTitle === '发货通知单详情') {
        this.deliveryNoticeDialogVisible = false
        return
      }
      this.$refs.deliveryNoticeFormRef.validate(async valid => {
        if (valid) {
          this.deliveryNoticeForm.noticeDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.deliveryNoticeFormTitle === '新增发货通知单') {
            await addDeliveryNotice(this.deliveryNoticeForm)
          } else if (this.deliveryNoticeFormTitle === '修改发货通知单') {
            await updateDeliveryNotice(this.deliveryNoticeForm)
          }
          this.deliveryNoticePage = await selectDeliveryNoticeList(this.searchForm)
          this.deliveryNoticeDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.deliveryNoticeFormTitle = '新增发货通知单'
      this.deliveryNoticeDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDeliveryNotice(row.id)
        if (this.deliveryNoticePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.deliveryNoticePage = await selectDeliveryNoticeList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.deliveryNoticeFormTitle = '修改发货通知单'
      this.deliveryNoticeDialogVisible = true
      this.selectDeliveryNoticeInfoById(row.id)
    },
    handleInfo (index, row) {
      this.deliveryNoticeFormTitle = '发货通知单详情'
      this.deliveryNoticeDialogVisible = true
      this.selectDeliveryNoticeInfoById(row.id)
    },
    selectDeliveryNoticeInfoById (id) {
      selectDeliveryNoticeInfo(id).then(res => {
        this.deliveryNoticeForm.id = res.id
        this.deliveryNoticeForm.customerId = res.customerId
        this.deliveryNoticeForm.receivingAddress = res.receivingAddress
        this.deliveryNoticeForm.orderNo = res.orderNo
        this.deliveryNoticeForm.deliveryDate = res.deliveryDate
        this.deliveryNoticeForm.deliveryWay = res.deliveryWay
        this.deliveryNoticeForm.totalPrice = res.totalPrice
        this.deliveryNoticeDetailList = res.noticeDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDeliveryNoticeList(this.searchForm).then(res => {
        this.deliveryNoticePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDeliveryNoticeList(this.searchForm).then(res => {
        this.deliveryNoticePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDeliveryNoticeList(this.searchForm).then(res => {
        this.deliveryNoticePage = res
      })
    },
    customerChange (value) {
      if (value) {
        let customer = this.customerList.find(item => item.id === value)
        this.deliveryNoticeForm.receivingAddress = customer.receivingAddress
      } else {
        this.deliveryNoticeForm.receivingAddress = ''
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    productChange ({ row }) {
      if (row.productId) {
        let product = this.productList.find(item => item.id === row.productId)
        row.spec = product.spec
      } else {
        row.spec = ''
      }
    },
    countAmount (row) {
      row.amount = XEUtils.multiply(row.quantity, row.unitPrice)
      return row.amount
    },
    countAllAmount (data) {
      return XEUtils.sum(data.map(row => this.countAmount(row)))
    },
    footerMethod ({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 4) {
            return '总价'
          } else if (columnIndex === 5) {
            this.deliveryNoticeForm.totalPrice = this.countAllAmount(data)
            return this.deliveryNoticeForm.totalPrice
          } else {
            return ''
          }
        })
      ]
    }
  }
}
</script>

<style>
</style>
